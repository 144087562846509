import React, { useState, useEffect } from 'react';
import backgroundImage from './background.png';
import { FaTwitter, FaPaperclip } from 'react-icons/fa';

const Navbar = () => {
  return (
    <nav style={{
      display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px',
      backgroundColor: '#ca1c5a', borderBottom: '1px solid #eaeaea', width: '100%', position: 'fixed', top: 0, zIndex: 1000
    }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
        <a href="#" style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold', fontSize: '18px', fontFamily: 'Fairfax' }}>TAMAFOXIES</a>
        <a href="#" style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold', fontSize: '18px', fontFamily: 'Fairfax' }}>FACTION BATTLES</a>
        <a href="#" style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold', fontSize: '18px', fontFamily: 'Fairfax' }}>FACTION CLAIM</a>
      </div>
      <div>
        <a href="https://twitter.com/TamaFoxies" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', color: 'black' }}>
          <FaTwitter size={24} />
        </a>
        <a href="https://tamafoxies.gitbook.io/litepaper/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '50px', color: 'black' }}>
          <FaPaperclip size={24} />
        </a>
      </div>
    </nav>
  );
};

function App() {
  const [walletAddress, setWalletAddress] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [countdown, setCountdown] = useState('');
  const [tweetLink, setTweetLink] = useState('');

  const handleAddressChange = (event) => setWalletAddress(event.target.value);

  const handleTwitterHandleChange = (event) => {
    const value = event.target.value;
    setTwitterHandle(value.startsWith('@') || value === '' ? value : `@${value}`);
  };

  useEffect(() => {
    const updateCountdown = () => {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      const whitelistHours = [0, 5, 10, 15, 20];
      setIsActive(whitelistHours.includes(currentHour));
      const nextHour = whitelistHours.find(hour => hour > currentHour) || whitelistHours[0];
      const remainingTime = Math.max((nextHour - new Date().getHours()) * 3600, 0) - (currentDate.getMinutes() * 60) - currentDate.getSeconds();
      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;
      setCountdown(`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`);
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

const handleJoinWhitelist = () => {
  const tweetText = encodeURIComponent(`I am joining the @Tamafoxies FoxieList!
I nominate @.... & @.... to join the FoxieList, be quick it's only open for this hour!
My FoxieList username: ${twitterHandle}
My FoxieListed wallet: ${walletAddress}`);

  window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
  setTweetLink('');
};

const handleSubmit = (event) => {
  event.preventDefault();
  // Handle tweet link submission logic here
};

return (
  <>
    <Navbar />
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh',
      backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
    }}>
      <input type="text" placeholder="Enter your Twitter handle" value={twitterHandle} onChange={handleTwitterHandleChange}
             style={{ fontSize: '20px', height: '40px', margin: '10px 0', fontFamily: 'Fairfax' }}/>
      <input type="text" placeholder="Enter your Ethereum wallet address" value={walletAddress} onChange={handleAddressChange}
             style={{ fontSize: '20px', height: '40px', margin: '10px 0', fontFamily: 'Fairfax' }}/>
      {isActive ? (
        <button onClick={handleJoinWhitelist}
                style={{
                  fontSize: '24px', fontFamily: 'Fairfax', padding: '12px 24px', borderRadius: '4px', backgroundColor: '#ca1c5a',
                  color: 'white', border: 'none', cursor: 'pointer', marginTop: '20px'
                }}>
          Join the whitelist
        </button>
      ) : (
        <div style={{ marginTop: '20px' }}>
          <p style={{ fontSize: '20px', fontFamily: 'Fairfax' }}>Join the whitelist (available in {countdown})</p>
        </div>
      )}
      {tweetLink !== '' && (
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
          <label style={{ fontSize: '20px', fontFamily: 'Fairfax', margin: '10px 0' }}>Insert link to your tweet:</label>
          <input type="text" value={tweetLink} onChange={(event) => setTweetLink(event.target.value)}
                 style={{ fontSize: '20px', height: '40px', margin: '10px 0', fontFamily: 'Fairfax' }}/>
          <button type="submit"
                  style={{
                    fontSize: '24px', fontFamily: 'Fairfax', padding: '12px 24px', borderRadius: '4px', backgroundColor: '#ca1c5a',
                    color: 'white', border: 'none', cursor: 'pointer'
                  }}>
            Submit
          </button>
        </form>
      )}
    </div>
  </>
);

}

export default App;
